import { Image } from 'react-datocms'
import Link from 'next/link'
import {
  Body,
  DetailImages,
  DetailPage,
  DetailVideo,
  LeadText,
  LinkList,
  OpeningHoursData,
  Services,
  Teaser,
  TeaserQuickLinkType,
  TeaserShopType,
} from '../../types/types'
import { renderIcon } from '../icons/render-icons'
import CloseIcon from '../icons/close-icon'
import {
  UIEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { RippleEffect } from '../ripple/ripple'
import { CardDetailLikeBtn } from './card-detail-like-btn'
import IconChevronLeft from '../icons/chevron-left'
import IconChevronRight from '../icons/chevron-right'
import IconChevronUp from '../icons/chevron-up'
import { useRouter } from 'next/router'
import { getLanguage } from '../../lib/get-language'
import { Lead } from '../detail-section/lead'
import { DetailBody } from '../detail-section/detail-body'
import { ExternalLinks } from '../detail-section/external-links'
import { BulletList } from '../detail-section/bullet-list'
import { ImageSlider } from '../detail-section/image-slider'
import ArrowBackIcon from '../icons/arrow-back-icon'
import { RenderVideo } from '../detail-section/render-video'
import { TeaserQuickLink } from '../detail-section/teaser-quick-link'
import { TeaserShop } from '../detail-section/teaser-shop'
import { DetailPill } from '../detail-section/detail-pill'
import { getColorSection } from '../../lib/get-color-section'
import { OpeningHours } from '../detail-section/opening-hours'
import { CardsStore, useCardsStore } from '../../hooks/store/use-cards'
import {
  getDetailPageId,
  useDetailPageStore,
} from '../../hooks/store/use-detail-page-id'
import {
  getFavorites,
  getFavoritesString,
  useFavoritesStore,
} from '../../hooks/store/use-favorites'
import { getNavLevel, useNavLevelStore } from '../../hooks/store/use-nav-leve'
import { getIsClient, useIsClientStore } from '../../hooks/store/use-is-client'
import { getHasTouch, useHasTouchStore } from '../../hooks/store/use-has-touch'
import {
  getBreakPoints,
  useBreakPointsStore,
} from '../../hooks/store/use-break-points'
import {
  getActiveCardString,
  useActiveCardStore,
} from '../../hooks/store/use-active-card'
import {
  getTaggedString,
  useTaggedCardIdsStore,
} from '../../hooks/store/use-tagged-card-ids'
import {
  getActiveTagListString,
  useActiveTagListStore,
} from '../../hooks/store/use-active-tag-list'
import { getOrbit, useOrbitStore } from '../../hooks/store/use-orbit-store'
import {
  getActiveTagString,
  useActiveTagStore,
} from '../../hooks/store/use-active-tag'
import { LogoAnimation } from '../logo-animation/logo-animation'
import { useTranslationsStore } from '../../hooks/store/use-translations'

const useFilteredCards = () => {
  const getCards = useCallback((state: CardsStore) => {
    return state.cards.filter(
      ({ type }) => type === 'card' || type === 'building',
    )
  }, [])
  const cards = useCardsStore(getCards)
  return { filteredCards: cards, maxFilteredCards: cards.length - 1 }
}

const useLink = () => {
  const cardId = useActiveCardStore(getActiveCardString)
  const favoritesString = useFavoritesStore(getFavoritesString)
  const taggedString = useTaggedCardIdsStore(getTaggedString)
  const activeTagListId = useActiveTagListStore(getActiveTagListString)
  const activeTagId = useActiveTagStore(getActiveTagString)
  const orbit = useOrbitStore(getOrbit)

  return useCallback(
    (detailPageId: string) => {
      return `${cardId}/${orbit}/${detailPageId}/false/0/${favoritesString}/${activeTagListId}/${activeTagId}/${taggedString}`
    },
    [
      cardId,
      orbit,
      favoritesString,
      activeTagListId,
      taggedString,
      activeTagId,
    ],
  )
}

const floatToPercent = (focalPoint: number | null) => {
  if (!focalPoint) {
    return 0
  }
  return focalPoint * 100
}

export const CardDetail = () => {
  const isLikedRef = useRef<HTMLDivElement>(null)
  const detailRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const headerContentRef = useRef<HTMLDivElement>(null)
  const headerLineRef = useRef<HTMLDivElement>(null)
  const likeBtnRef = useRef<HTMLButtonElement>(null)
  const subtitleRef = useRef<HTMLSpanElement>(null)
  const { locale, query } = useRouter()
  const detailPageId = useDetailPageStore(getDetailPageId)
  const favorites = useFavoritesStore(getFavorites)
  const navLevel = useNavLevelStore(getNavLevel)

  const url = useLink()

  const { filteredCards, maxFilteredCards } = useFilteredCards()

  const currentIndex = useMemo(() => {
    return filteredCards.findIndex(({ id }) => id === detailPageId)
  }, [detailPageId, filteredCards])

  const nextId = useMemo(() => {
    if (currentIndex + 1 <= maxFilteredCards) {
      return filteredCards[currentIndex + 1]?.id || '-'
    }
    return filteredCards[maxFilteredCards]?.id || '-'
  }, [currentIndex])

  const prevId = useMemo(() => {
    if (currentIndex - 1 >= 0) {
      return filteredCards[currentIndex - 1]?.id || '-'
    }
    return filteredCards[0]?.id || '-'
  }, [currentIndex])

  const setFavorites = useFavoritesStore(
    useCallback((state) => state.setFavorites, []),
  )

  const notFoundText = useTranslationsStore(
    useCallback(
      (state) => state.translations.find(({ id }) => id === '62271364'),
      [],
    ),
  )

  const notFoundTitle = useTranslationsStore(
    useCallback(
      (state) => state.translations.find(({ id }) => id === '62271365'),
      [],
    ),
  )

  const isClient = useIsClientStore(getIsClient)
  const hasTouch = useHasTouchStore(getHasTouch)
  const breakPoints = useBreakPointsStore(getBreakPoints)
  const [isReady, setIsReady] = useState(false)
  const [detailPage, setDetailPage] = useState<DetailPage | null>(null)
  const [error, setError] = useState(false)

  const scrollToTop = () => {
    if (!detailRef.current) {
      return
    }
    detailRef.current.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleOnFavClick = useCallback(() => {
    if (!detailPage) {
      return
    }
    const foundFavIndex = favorites.findIndex((fav) => fav === detailPage.id)
    if (foundFavIndex >= 0) {
      favorites.splice(foundFavIndex, 1)
    } else {
      favorites.push(detailPage.id)
    }
    setFavorites([...favorites])
  }, [detailPage, favorites])

  useEffect(() => {
    if (!isClient || !detailPageId) {
      return
    }
    const lang = getLanguage(locale)

    const fetchData = async () => {
      const preview = query.preview === 'true' ? '?preview=true' : ''
      await fetch(`/api/detail-page/${lang}/${detailPageId}${preview}`)
        .then((data) => {
          return data.json()
        })
        .then((r) => {
          setIsReady(true)
          setDetailPage(r)
          return r
        })
        .catch((err) => {
          setIsReady(true)
          setDetailPage(null)
          setError(true)
          console.log(err)
        })
    }
    fetchData()
  }, [detailPageId, isClient])

  const handleScroll: UIEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (
        !hasTouch ||
        !headerRef.current ||
        !headerLineRef.current ||
        !likeBtnRef.current ||
        !subtitleRef.current
      ) {
        return
      }
      const contentHeight = headerContentRef.current?.offsetHeight || 0
      const maxHeight = breakPoints === 'sm' ? 320 : 600
      const headerMinHeight = breakPoints === 'sm' ? 94 : 177

      const minHeight =
        contentHeight <= headerMinHeight ? headerMinHeight : contentHeight

      const maxScrollOffset = maxHeight - minHeight

      const scrollOffset = (e.target as HTMLDivElement).scrollTop

      if (scrollOffset < maxScrollOffset && scrollOffset >= 0) {
        headerRef.current.style.height = `${maxHeight - scrollOffset}px`
        headerLineRef.current.style.top = `${maxHeight - scrollOffset}px`
      }
      if (scrollOffset >= maxScrollOffset) {
        headerRef.current.style.height = `${minHeight}px`
        headerLineRef.current.style.top = `${minHeight}px`
      }
      likeBtnRef.current.style.display =
        scrollOffset > maxHeight / 2 ? 'none' : 'flex'
      subtitleRef.current.style.display =
        scrollOffset > maxHeight / 2 ? 'none' : 'block'
    },
    [breakPoints, hasTouch],
  )

  return isReady ? (
    <div className="absolute left-0 top-0 w-full h-full z-50 bg-bg-900 pointer-events-auto z-[60]">
      <div
        className="flex flex-col desktop:flex-row w-full h-full pointer-events-auto overflow-y-scroll"
        ref={detailRef}
        onScroll={handleScroll}
      >
        <header
          className="fixed desktop:sticky top-0 left-0 w-full h-[320px] md:h-[600px] desktop:h-full desktop:w-1/2 flex-shrink-0 z-50 pointer-events-none bg-bg-900"
          ref={headerRef}
        >
          {isReady && detailPage?.image && (
            <div className="w-full h-full absolute left-0 top-0">
              <Image
                data={detailPage?.image.responsiveImage}
                className="w-full h-full top-0 left-0 absolute"
                pictureClassName="object-cover"
                pictureStyle={{
                  objectPosition: `${floatToPercent(
                    detailPage.image.focalPoint?.x,
                  )}% ${floatToPercent(detailPage.image.focalPoint?.y)}%`,
                }}
              />
            </div>
          )}
          <div className="absolute left-0 top-0 w-full h-full bg-gradient-to-t from-transparent to-dark mix-blend-multiply opacity-60" />
          <div
            className="relative flex px-4.5 md:px-0 md:max-w-[502px] mx-auto desktop:px-15 py-4.5 md:py-17 desktop:py-20 justify-between md:mx-auto desktop:max-w-full"
            ref={headerContentRef}
          >
            <div className="relative font-black text-3xl md:text-5.5xl desktop:text-6.5xl text-typo-100 pt-2 ">
              {isReady && detailPage?.title}
              {error && notFoundTitle?.text}
              <span
                className="block mt-1 font-normal text-lg desktop:text-2xl"
                ref={subtitleRef}
              >
                {isReady && detailPage?.subtitle}
              </span>
            </div>
            <Link href={url('-')} passHref shallow>
              <a className="pointer-events-auto">
                <RippleEffect
                  className="w-13 h-13 flex justify-center items-center bg-bg-800 rounded-full cursor-pointer desktop:hidden flex-shrink-0"
                  onClick={() => {
                    setDetailPage(null)
                  }}
                >
                  <CloseIcon />
                </RippleEffect>
              </a>
            </Link>
          </div>
        </header>
        <div
          className="fixed desktop:sticky top-[320px] md:top-[600px] desktop:top-0 left-0 w-full desktop:w-2 h-1 desktop:h-full flex justify-end items-center bg-current flex-shrink-0 px-4.5 desktop:px-0  z-50 pointer-events-none"
          ref={headerLineRef}
          style={{
            color: detailPage ? getColorSection(detailPage) : undefined,
          }}
        >
          <div className="w-full md:max-w-[502px] mx-auto flex justify-end">
            {detailPage && (
              <CardDetailLikeBtn
                className="flex desktop:hidden z-50 pointer-events-auto"
                card={detailPage}
                isLikedRef={isLikedRef}
                onClick={handleOnFavClick}
                likeWrapperRef={likeBtnRef}
              />
            )}
          </div>
        </div>

        <div className="mt-[320px] md:mt-[600px] desktop:mt-0 w-full px-4.5 desktop:w-1/2">
          <div className="relative desktop:pt-16 text-typo-300 mx-auto flex flex-col desktop:mx-auto md:max-w-[502px]">
            <article className="text-typo-100">
              <div className="w-full pb-16 flex justify-between">
                {detailPage ? (
                  <CardDetailLikeBtn
                    className="hidden desktop:flex"
                    card={detailPage}
                    isLikedRef={isLikedRef}
                    onClick={handleOnFavClick}
                  />
                ) : (
                  <div />
                )}
                <Link href={url('-')} passHref shallow>
                  <a>
                    <RippleEffect
                      className="w-13 h-13 justify-center items-center bg-bg-800 rounded-full cursor-pointer hidden desktop:flex"
                      onClick={() => {
                        setDetailPage(null)
                      }}
                    >
                      {navLevel.level > 0 ? <ArrowBackIcon /> : <CloseIcon />}
                    </RippleEffect>
                  </a>
                </Link>
              </div>
              {/*---------------------------- content start ----------------------------*/}
              <div className="text-sm py-6 md:mx-0 mx-[-18px]">
                <div className="mx-[18px] md:mx-0">
                  {detailPage?.building !== 'Aussenraum' &&
                    detailPage?.building}{' '}
                  {detailPage?.floorName}
                </div>
                {detailPage?.icons.length || detailPage?.tags.length ? (
                  <div className="pt-2 flex desktop:flex-wrap overflow-x-scroll desktop:overflow-x-visible my-[-5px] pb-5">
                    {detailPage?.icons.map((icon) => {
                      if (!icon) {
                        return
                      }

                      return (
                        <div
                          key={`card_icon_wrapper_${detailPage?.id}_${icon}`}
                          className="mr-3 w-8 h-8 rounded-full flex justify-center items-center bg-bg-800 my-[5px] flex-shrink first:ml-[18px] md:first:ml-0"
                        >
                          {renderIcon({
                            name: icon,
                            withBG: false,
                            key: `card_icon_${detailPage?.id}_${icon}`,
                          })}
                        </div>
                      )
                    })}
                    {detailPage?.tags.length
                      ? detailPage?.tags.map((tag, index) => {
                          return (
                            <div
                              className="flex-shrink-0 my-[5px] mr-3 first:ml-[18px] md:first:ml-0"
                              key={`${tag.name}_${tag.tagType.name}_${index}`}
                            >
                              <DetailPill tag={tag} />
                            </div>
                          )
                        })
                      : null}
                  </div>
                ) : null}
              </div>
              {error && <Lead text={notFoundText?.text || ''} />}
              {detailPage?.content.length
                ? detailPage?.content.map((section, index) => {
                    if (section._modelApiKey === 'lead_text') {
                      const lead = section as LeadText
                      return (
                        <Lead
                          text={lead.text}
                          key={`detailPage_content_${index}`}
                        />
                      )
                    }
                    if (section._modelApiKey === 'paragraph') {
                      const body = section as Body
                      return (
                        <DetailBody
                          data={body.text}
                          key={`detailPage_content_${index}`}
                        />
                      )
                    }
                    if (section._modelApiKey === 'linklist') {
                      const linkList = section as LinkList
                      return (
                        <ExternalLinks
                          linkList={linkList}
                          key={`detailPage_content_${index}`}
                        />
                      )
                    }
                    if (section._modelApiKey === 'service') {
                      const list = section as Services
                      return (
                        <BulletList
                          title={list.title}
                          list={list.list}
                          key={`detailPage_content_${index}`}
                        />
                      )
                    }
                    if (section._modelApiKey === 'image') {
                      const images = section as DetailImages
                      return (
                        <ImageSlider
                          images={images.image}
                          key={`detailPage_content_${index}`}
                        />
                      )
                    }
                    if (section._modelApiKey === 'detail_video') {
                      const { video } = section as DetailVideo
                      return (
                        <RenderVideo
                          video={video.video}
                          title={video.title}
                          caption={video.caption}
                          key={`detailPage_content_${index}`}
                        />
                      )
                    }

                    if (section._modelApiKey === 'teaser') {
                      const teasers = section as Teaser
                      return (
                        <div className="w-full overflow-x-scroll desktop:overflow-x-visible py-6">
                          <div className="flex h-full desktop:flex-wrap justify-between mx-[-8px] desktop:my-[-8px]">
                            {teasers.teaser.map((teaser) => {
                              if (teaser._modelApiKey === 'teaser_quicklink') {
                                return (
                                  <TeaserQuickLink
                                    teaser={teaser as TeaserQuickLinkType}
                                    key={`detailPage_content_${teaser.id}_${index}`}
                                  />
                                )
                              }
                              if (teaser._modelApiKey === 'teaser_shop') {
                                return (
                                  <TeaserShop
                                    teaser={teaser as TeaserShopType}
                                    key={`detailPage_content_${teaser.id}_${index}`}
                                  />
                                )
                              }
                            })}
                          </div>
                        </div>
                      )
                    }
                    if (section._modelApiKey === 'opening_hour') {
                      const openingHours = section as OpeningHoursData
                      return (
                        <OpeningHours
                          data={openingHours.data}
                          display={openingHours.display}
                          key={`detailPage_content_${index}`}
                        />
                      )
                    }
                  })
                : null}

              {/*---------------------------- content end ----------------------------*/}
            </article>
            {!error && (
              <footer className="h-[110px] flex items-center justify-between flex-shrink-0 border-t border-mono-700">
                {currentIndex > 0 ? (
                  <Link href={url(prevId)} shallow>
                    <a>
                      <RippleEffect className="flex justify-center items-center w-13 h-13 bg-bg-800 rounded-full">
                        <IconChevronLeft />
                      </RippleEffect>
                    </a>
                  </Link>
                ) : (
                  <div className="w-13" />
                )}
                <IconChevronUp
                  className="cursor-pointer"
                  onClick={scrollToTop}
                />
                {currentIndex < maxFilteredCards ? (
                  <Link href={url(nextId)} shallow>
                    <a>
                      <RippleEffect className="flex justify-center items-center w-13 h-13 bg-bg-800 rounded-full">
                        <IconChevronRight />
                      </RippleEffect>
                    </a>
                  </Link>
                ) : (
                  <div className="w-13" />
                )}
              </footer>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <LogoAnimation />
  )
}
