import Head from 'next/head'
import { OpenGraphProps } from '../types/types'

export const OpenGraph = ({
  name,
  image,
  url,
  description,
}: OpenGraphProps) => {
  return (
    <Head>
      <meta property="og:title" content={name} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
    </Head>
  )
}
